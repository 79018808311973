import React, { Fragment } from 'react';
import Hero from '../components/agents/hero';
import Why from '../components/agents/why';
import Reviews from '../components/agents/reviews';
import Contact from '../components/contact';

import bgReview from '../images/group-of-people-working-out-business-plan-in-an-office.jpg';

const itemsReview = [
  {
    id: null,
    src:"/team-4.jpg",
    review: "Muy buena experiencia. He logrado concretar la venta de mis propiedades en tiempo record, mis clientes están felices!",
    author: "Romina Marschhausen"
  },                    
  {
    id: null,
    src:"/team-1.png",
    review: "Son un gran equipo de profesionales. Me han entregado todas las herramientas para dedicarme a lo realmente importante, que es entender las necesidades de mis clientes.",
    author: "Diego Valdenegro"
  },
  {
    id: null,
    src:"/team-5.jpg",
    review: "El equipo es excelente, me ayudaron a solucionar todas mis dudas y a usar la plataforma. Desde que soy parte del equipo, mi negocio ha mejorado considerablemente.",
    author: "Constanza Viveros"
  },     
]

export default ()=> {

  return(
    <Fragment>
      <Hero />
      <Why />
      <Reviews
        items={itemsReview}
        bgImg={bgReview}
        title="Emprende con Invierte Bien Raíz y se parte del equipo que esta revolucionando la forma de vender propiedades."
      />
      <Contact />
    </Fragment>
  )
}